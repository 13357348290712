<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="space-between">
        <h4>Romaneios cadastrados</h4>
        <el-button
          type="primary"
          icon="el-icon-plus"
          size="medium"
          @click="openPackingListModal(null)"
        ></el-button>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="packings"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="code" label="Nº"> </el-table-column>
      <el-table-column
        prop="bought_at"
        label="data"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>

      <el-table-column label="produtor">
        <template #default="c">
          <el-select
            remote
            :modelValue="c?.row?.provider?.uid"
            @update:modelValue="updateProviderSelect(c.row, $event)"
            size="medium"
            value-key="uid"
            @select="() => updatePacking(c.row)"
            :loading="!partners"
          >
            <el-option
              v-for="item in Partners"
              :key="item.uid"
              :label="`${item.name?.slice(0, 15)}${
                item?.name?.length > 15 ? '...' : ''
              }`"
              :value="item.uid"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="animais">
        <template #default="p">
          {{ `${p?.row?.total || 0}` }}
        </template>
      </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="emp">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                title="Enviar para conferência?"
                @confirm="
                  () => (emp.row.status = 'checking') | updatePacking(emp.row)
                "
              >
                <template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-finished"></i
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openPackingListModal(emp.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openPackingListCheckModal(emp.row)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-popconfirm
                @confirm="deletePacking(emp.row)"
                title="
              Remover romaneio?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <packing-list-modal
      :showModal="showPackingListModal"
      :packing="packing"
      :providers="partners"
      @close-modal="showPackingListModal = false"
      @should-update="fetchPackings"
    ></packing-list-modal>
    <packing-list-animals-modal
      :showModal="showPackingListAnimalsModal"
      :packing="packing"
      :providers="partners"
      @close-modal="showPackingListAnimalsModal = false"
      @should-update="fetchPackings"
    ></packing-list-animals-modal>
  </el-card>
</template>

<script>
import PackingListModal from "./modals/PackingListModal.vue";
import PackingListAnimalsModal from "./modals/PackingListAnimalsModal.vue";
import { ElNotification } from "element-plus";

export default {
  name: "PackingListPage",
  components: { PackingListModal, PackingListAnimalsModal },
  data: () => ({
    hasError: false,
    isLoading: false,
    packings: [],
    showPackingListAnimalsModal: false,
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
      timeStyle: "short",
    }),
    partners: null,
    packing: null,
    showPackingListModal: false,
  }),
  mounted() {
    this.fetchPackings();
    this.fetchPartners();
  },
  computed: {
    Partners() {
      return this.partners || [];
    },
  },
  methods: {
    updateProviderSelect(packing, data) {
      packing.provider_id = data;
      this.updatePacking(packing);
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    fetchPartners() {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({ is_provider: true });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => (this.partners = json));
    },
    deletePacking(packing) {
      if (packing)
        fetch(`${this.$store.state.apiUrl}packings/${packing.uid}`, {
          credentials: "include",
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) return response.json();
            else throw response.text();
          })
          .then(() => {
            this.fetchPackings();
            this.$notify({
              title: "Romaneio removido com sucesso.",
              type: "success",
              position: "bottom-right",
            });
          })
          .catch((e) => {
            this.$notify({
              title: "Não foi possível remover ao romaneio",
              type: "error",
              message: e.message,
              position: "bottom-right",
            });
          });
    },
    openPackingListModal(e) {
      (this.packing = e), (this.showPackingListModal = true);
    },
    openPackingListCheckModal(e) {
      (this.packing = e), (this.showPackingListAnimalsModal = true);
    },
    updatePacking(packing) {
      if (packing)
        fetch(`${this.$store.state.apiUrl}packings/${packing.uid}`, {
          credentials: "include",
          method: "PUT",
          body: JSON.stringify({ ...packing }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status == 200) return await response.json();
            else throw await response.json();
          })
          .then(
            (e) => {
              this.fetchPackings();
              ElNotification.success({
                title: e.message,
                position: "bottom-right",
              });
            },
            (e) => {
              ElNotification.error({
                title: "Não foi possível alterar o romaneio",
                message: e.message,
                position: "bottom-right",
              });
            }
          );
    },
    fetchPackings() {
      this.isLoading = true;
      const url = new URL(`${this.$store.state.apiUrl}packings`);
      url.search = new URLSearchParams({ status: "created" });
      fetch(url, {
        credentials: "include",
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          else return response.text();
        })
        .then((json) => {
          this.packings = json;
          if (this.packing) {
            const newPacking = this.packings?.filter(
              (p) => p.uid === this.packing.uid
            );

            if (newPacking?.length) this.packing = newPacking[0];
          }
        })
        .catch(() => (this.hasError = true))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>
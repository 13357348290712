<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Data de cadastro:</h4>
        <el-date-picker
          :modelValue="new Date(packing_.created_at)"
          @update:modelValue="packing_.created_at = $event"
          type="date"
          size="medium"
          format="DD/MM/YYYY HH:mm:ss"
        >
        </el-date-picker>
      </el-row>
      <base-input v-model="packing_.code" mask="#*" label="Nº:"></base-input>
      <base-input
        v-model="packing_.total"
        mask="#*"
        label="Qtd. animais:"
      ></base-input>
      <base-input v-model="packing_.range" label="Sequência:"></base-input>

      <el-select
        v-model="packing_.provider_id"
        filterable
        remote
        size="medium"
        reserve-keyword
        placeholder="Digite o nome do produtor para buscar"
        :remote-method="fetchProviders"
        :loading="isLoadingSearch"
      >
        <template #prefix>Produtor:</template>
        <el-option
          v-for="item in providers_"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";

export default {
  props: ["packing", "showModal", "providers"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      packing_: {},
      providers_: null,
      isLoadingSave: false,
      isLoadingSearch: true,
      systemActions: [],
      intervalPooling: null,
    };
  },
  watch: {
    packing(v) {
      this.packing_ = { ...(v || {}) };
      if (v?.provider?.uid) this.packing_.provider_id = v.provider.uid;
      this.providers_ = this.providers;
    },
  },
  computed: {
    Standards() {
      return ["Boa", "Média boa", "Média", "Desossa", "Novilha", "Angus"];
    },
    ModalTitle() {
      return !this.isNew ? "Editar romaneio" : "Cadastrar romaneio";
    },
    isNew() {
      return !this.packing;
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  methods: {
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateCascass() : this.createCarcass();
    },
    createCarcass() {
      fetch(`${this.$store.state.apiUrl}packings`, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(this.packing_),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            this.$emit("close-modal");
            this.packing_ = {};
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao cadastrar o romaneio",
              message: e.message,
              position: "bottom-right",
            });
          }
        );
    },
    updateCascass() {
      fetch(`${this.$store.state.apiUrl}packings/${this.packing_.uid}`, {
        credentials: "include",
        method: "PUT",
        body: JSON.stringify({ ...this.packing_ }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then(
          (e) => {
            this.$emit("should-update");
            ElNotification.success({
              title: e.message,
              position: "bottom-right",
            });
            this.$emit("close-modal");
          },
          (e) => {
            ElNotification.error({
              title: "Ocorreu um erro ao atualizar a carcaça",
              message: e.message,
              position: "bottom-right",
            });
          }
        )
        .finally(() => {
          this.isLoadingSave = false;
          this.shouldShow = false;
        });
    },
    fetchProviders(queryName) {
      const url = new URL(`${this.$store.state.apiUrl}partners`);
      url.search = new URLSearchParams({
        is_provider: true,
        searchName: queryName,
      });
      fetch(url, {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          this.isLoadingSearch = false;
          if (response.status == 200) return await response.json();
          else throw await response.json();
        })
        .then((e) => (this.providers_ = e));
    },
  },
  name: "CarcassModal",
};
</script>
<style>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
</style>